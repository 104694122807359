/***
====================================================================
	Fonts
====================================================================
 ***/
 $font_primary: 'Poppins', sans-serif;
 $font_secondary: 'Roboto', sans-serif;
 
 /***
 ====================================================================
     Colors
 ====================================================================
  ***/
 
 $blue_c: #242A67;
 $red_c: #DB0C15;
 $text_c: #333;
 $dark_color: #888;
 $primary: $blue_c;
 $secondary: $red_c;
 //$success:       $green !default;
 //$info:          $cyan !default;
 //$warning:       $yellow !default;
 //$danger:        $red !default;
 //$light:         $gray-100 !default;
 //$dark:          $gray-800 !default;
 
 /***
 ====================================================================
     transition
 ====================================================================
  ***/
 $blog_transition: all .3s ease-out;
 /***
 ====================================================================
     clearfix
 ====================================================================
  ***/
 %clearfix {
     &:after {
         content: "";
         display: table;
         clear: both;
     }
 }
 
 .clearfix {
     &:after {
         content: "";
         display: table;
         clear: both;
     }
 }
 
 /*
 --------------------------------------------------------------------
 We can then extend the .clearfix class wherever necessary.
 --------------------------------------------------------------------
 .wrap {
     ...
     @extend .clearfix;
 }
 .main-header {
     ...
     @extend .clearfix;
 }*/
 /***
 ====================================================================
     Spacer
 ====================================================================
  ***/
 @for $i from 1 through 10 {
     .spacer#{$i}0 {
         height: #{$i}0px;
     }
 }
 
 /***
 ====================================================================
     media queries
 ====================================================================
  ***/
//  $min400: 400px;
//  $max400: max-width 399px;
//  $min480: 480px;
//  $max480: max-width 479px;
//  $min576: 576px;
//  $max576: max-width 575px;
//  $min768: 768px;
//  $max768: max-width 767px;
//  $min992: 992px;
//  $max992: max-width 991px;
//  $min1200: 1200px;
//  $max1200: max-width 1199px;
//  $min1600: 1600px;
//  $max1600: max-width 1599px;
 /*
 media query example
 
 @include breakpoint($min768) {
         position: fixed;
     }
 */
 
 /***
 ====================================================================
     Mixins for Rem Font Sizing
 ====================================================================
  ***/
 
 //@mixin font-size($sizeValue: 16) {
 //    font-size: $sizeValue  + px;
 //    font-size: ($sizeValue / 10) + rem;
 //}
 
 /*
 @include font-size(16); -- 16px 1.6rem
 */
 
 /***
 ====================================================================
     transition
 ====================================================================
  ***/
 $transition_main_all: all .15s ease-in-out;
 
 /***
 ====================================================================
     Shadows
 ====================================================================
  ***/
 $box_shadow_1: 2px 2px 11px 0 rgba(0, 0, 0, 0.1);
 $box_shadow_2: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
 
 /***
 ====================================================================
     Bootstrap them overrides
 ====================================================================
  ***/
 $enable-rounded: false !default;
 $theme-colors: (
         "primary": $blue_c,
         "secondary": $red_c,
         "danger": #ff4136,
         "dark_primary":$dark_color,
 );
 //$font-weight-normal: 300;
 //$font-weight-bold: 400;