
@import  'variables';

/***
========================================================================================================================
Bootstrap
https://github.com/twbs/bootstrap
========================================================================================================================
 */
//  @import '~bootstrap/scss/bootstrap';
 @import "~bootstrap/scss/functions";
 @import "~bootstrap/scss/variables";
 @import "~bootstrap/scss/mixins";
 @import "~bootstrap/scss/root";
 @import "~bootstrap/scss/reboot";
 @import "~bootstrap/scss/type";
 @import "~bootstrap/scss/images";
//  @import "~bootstrap/scss/code";
 @import "~bootstrap/scss/grid";
 @import "~bootstrap/scss/tables";
 @import "~bootstrap/scss/forms";
 @import "~bootstrap/scss/buttons";
 @import "~bootstrap/scss/transitions";
 @import "~bootstrap/scss/dropdown";
 @import "~bootstrap/scss/button-group";
 @import "~bootstrap/scss/input-group";
 @import "~bootstrap/scss/custom-forms";
 @import "~bootstrap/scss/nav";
 @import "~bootstrap/scss/navbar";
 @import "~bootstrap/scss/card";
 @import "~bootstrap/scss/breadcrumb";
 @import "~bootstrap/scss/pagination";
 //@import "badge";
 //@import "jumbotron";
 @import "~bootstrap/scss/alert";
 //@import "progress";
 @import "~bootstrap/scss/media";
 //@import "list-group";
 @import "~bootstrap/scss/close";
 //@import "toasts";
 @import "~bootstrap/scss/modal";
 //@import "tooltip";
 //@import "popover";
 @import "~bootstrap/scss/carousel";
 //@import "spinners";
 @import "~bootstrap/scss/utilities";


 /***
========================================================================================================================
Icons
========================================================================================================================
 */
 @import 'icons/linearicons';
 @import 'icons/font-awesome-free-5.15.1';
 @import 'icons/themify-icons';
 @import 'icons/simple-line-icons';