@font-face {
    font-family: 'Linearicons';
    src:  url('../../fonts/Linearicons.eot');
    src:  url('../../fonts/Linearicons.eot') format('embedded-opentype'),
    url('../../fonts/Linearicons.ttf') format('truetype'),
    url('../../fonts/Linearicons.woff') format('woff'),
    url('../../fonts/Linearicons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  
    src: url(../../fonts/Linearicons-1.eot); /* For IE6-8 */
    src: local('Linearicons'),
    local('Linearicons'),
    url('../../fonts/Linearicons-1.woff') format('woff'),
    url('../../fonts/Linearicons-1.ttf') format('truetype');
  }
  
  .linearicons,
  
  [class^="linearicons-"], [class*=" linearicons-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Linearicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .linearicons-home:before {
    content: "\e900";
  }
  .linearicons-home2:before {
    content: "\e901";
  }
  .linearicons-home3:before {
    content: "\e902";
  }
  .linearicons-home4:before {
    content: "\e903";
  }
  .linearicons-home5:before {
    content: "\e904";
  }
  .linearicons-home6:before {
    content: "\e905";
  }
  .linearicons-bathtub:before {
    content: "\e906";
  }
  .linearicons-toothbrush:before {
    content: "\e907";
  }
  .linearicons-bed:before {
    content: "\e908";
  }
  .linearicons-couch:before {
    content: "\e909";
  }
  .linearicons-chair:before {
    content: "\e90a";
  }
  .linearicons-city:before {
    content: "\e90b";
  }
  .linearicons-apartment:before {
    content: "\e90c";
  }
  .linearicons-pencil:before {
    content: "\e90d";
  }
  .linearicons-pencil2:before {
    content: "\e90e";
  }
  .linearicons-pen:before {
    content: "\e90f";
  }
  .linearicons-pencil3:before {
    content: "\e910";
  }
  .linearicons-eraser:before {
    content: "\e911";
  }
  .linearicons-pencil4:before {
    content: "\e912";
  }
  .linearicons-pencil5:before {
    content: "\e913";
  }
  .linearicons-feather:before {
    content: "\e914";
  }
  .linearicons-feather2:before {
    content: "\e915";
  }
  .linearicons-feather3:before {
    content: "\e916";
  }
  .linearicons-pen2:before {
    content: "\e917";
  }
  .linearicons-pen-add:before {
    content: "\e918";
  }
  .linearicons-pen-remove:before {
    content: "\e919";
  }
  .linearicons-vector:before {
    content: "\e91a";
  }
  .linearicons-pen3:before {
    content: "\e91b";
  }
  .linearicons-blog:before {
    content: "\e91c";
  }
  .linearicons-brush:before {
    content: "\e91d";
  }
  .linearicons-brush2:before {
    content: "\e91e";
  }
  .linearicons-spray:before {
    content: "\e91f";
  }
  .linearicons-paint-roller:before {
    content: "\e920";
  }
  .linearicons-stamp:before {
    content: "\e921";
  }
  .linearicons-tape:before {
    content: "\e922";
  }
  .linearicons-desk-tape:before {
    content: "\e923";
  }
  .linearicons-texture:before {
    content: "\e924";
  }
  .linearicons-eye-dropper:before {
    content: "\e925";
  }
  .linearicons-palette:before {
    content: "\e926";
  }
  .linearicons-color-sampler:before {
    content: "\e927";
  }
  .linearicons-bucket:before {
    content: "\e928";
  }
  .linearicons-gradient:before {
    content: "\e929";
  }
  .linearicons-gradient2:before {
    content: "\e92a";
  }
  .linearicons-magic-wand:before {
    content: "\e92b";
  }
  .linearicons-magnet:before {
    content: "\e92c";
  }
  .linearicons-pencil-ruler:before {
    content: "\e92d";
  }
  .linearicons-pencil-ruler2:before {
    content: "\e92e";
  }
  .linearicons-compass:before {
    content: "\e92f";
  }
  .linearicons-aim:before {
    content: "\e930";
  }
  .linearicons-gun:before {
    content: "\e931";
  }
  .linearicons-bottle:before {
    content: "\e932";
  }
  .linearicons-drop:before {
    content: "\e933";
  }
  .linearicons-drop-crossed:before {
    content: "\e934";
  }
  .linearicons-drop2:before {
    content: "\e935";
  }
  .linearicons-snow:before {
    content: "\e936";
  }
  .linearicons-snow2:before {
    content: "\e937";
  }
  .linearicons-fire:before {
    content: "\e938";
  }
  .linearicons-lighter:before {
    content: "\e939";
  }
  .linearicons-knife:before {
    content: "\e93a";
  }
  .linearicons-dagger:before {
    content: "\e93b";
  }
  .linearicons-tissue:before {
    content: "\e93c";
  }
  .linearicons-toilet-paper:before {
    content: "\e93d";
  }
  .linearicons-poop:before {
    content: "\e93e";
  }
  .linearicons-umbrella:before {
    content: "\e93f";
  }
  .linearicons-umbrella2:before {
    content: "\e940";
  }
  .linearicons-rain:before {
    content: "\e941";
  }
  .linearicons-tornado:before {
    content: "\e942";
  }
  .linearicons-wind:before {
    content: "\e943";
  }
  .linearicons-fan:before {
    content: "\e944";
  }
  .linearicons-contrast:before {
    content: "\e945";
  }
  .linearicons-sun-small:before {
    content: "\e946";
  }
  .linearicons-sun:before {
    content: "\e947";
  }
  .linearicons-sun2:before {
    content: "\e948";
  }
  .linearicons-moon:before {
    content: "\e949";
  }
  .linearicons-cloud:before {
    content: "\e94a";
  }
  .linearicons-cloud-upload:before {
    content: "\e94b";
  }
  .linearicons-cloud-download:before {
    content: "\e94c";
  }
  .linearicons-cloud-rain:before {
    content: "\e94d";
  }
  .linearicons-cloud-hailstones:before {
    content: "\e94e";
  }
  .linearicons-cloud-snow:before {
    content: "\e94f";
  }
  .linearicons-cloud-windy:before {
    content: "\e950";
  }
  .linearicons-sun-wind:before {
    content: "\e951";
  }
  .linearicons-cloud-fog:before {
    content: "\e952";
  }
  .linearicons-cloud-sun:before {
    content: "\e953";
  }
  .linearicons-cloud-lightning:before {
    content: "\e954";
  }
  .linearicons-cloud-sync:before {
    content: "\e955";
  }
  .linearicons-cloud-lock:before {
    content: "\e956";
  }
  .linearicons-cloud-gear:before {
    content: "\e957";
  }
  .linearicons-cloud-alert:before {
    content: "\e958";
  }
  .linearicons-cloud-check:before {
    content: "\e959";
  }
  .linearicons-cloud-cross:before {
    content: "\e95a";
  }
  .linearicons-cloud-crossed:before {
    content: "\e95b";
  }
  .linearicons-cloud-database:before {
    content: "\e95c";
  }
  .linearicons-database:before {
    content: "\e95d";
  }
  .linearicons-database-add:before {
    content: "\e95e";
  }
  .linearicons-database-remove:before {
    content: "\e95f";
  }
  .linearicons-database-lock:before {
    content: "\e960";
  }
  .linearicons-database-refresh:before {
    content: "\e961";
  }
  .linearicons-database-check:before {
    content: "\e962";
  }
  .linearicons-database-history:before {
    content: "\e963";
  }
  .linearicons-database-upload:before {
    content: "\e964";
  }
  .linearicons-database-download:before {
    content: "\e965";
  }
  .linearicons-server:before {
    content: "\e966";
  }
  .linearicons-shield:before {
    content: "\e967";
  }
  .linearicons-shield-check:before {
    content: "\e968";
  }
  .linearicons-shield-alert:before {
    content: "\e969";
  }
  .linearicons-shield-cross:before {
    content: "\e96a";
  }
  .linearicons-lock:before {
    content: "\e96b";
  }
  .linearicons-rotation-lock:before {
    content: "\e96c";
  }
  .linearicons-unlock:before {
    content: "\e96d";
  }
  .linearicons-key:before {
    content: "\e96e";
  }
  .linearicons-key-hole:before {
    content: "\e96f";
  }
  .linearicons-toggle-off:before {
    content: "\e970";
  }
  .linearicons-toggle-on:before {
    content: "\e971";
  }
  .linearicons-cog:before {
    content: "\e972";
  }
  .linearicons-cog2:before {
    content: "\e973";
  }
  .linearicons-wrench:before {
    content: "\e974";
  }
  .linearicons-screwdriver:before {
    content: "\e975";
  }
  .linearicons-hammer-wrench:before {
    content: "\e976";
  }
  .linearicons-hammer:before {
    content: "\e977";
  }
  .linearicons-saw:before {
    content: "\e978";
  }
  .linearicons-axe:before {
    content: "\e979";
  }
  .linearicons-axe2:before {
    content: "\e97a";
  }
  .linearicons-shovel:before {
    content: "\e97b";
  }
  .linearicons-pickaxe:before {
    content: "\e97c";
  }
  .linearicons-factory:before {
    content: "\e97d";
  }
  .linearicons-factory2:before {
    content: "\e97e";
  }
  .linearicons-recycle:before {
    content: "\e97f";
  }
  .linearicons-trash:before {
    content: "\e980";
  }
  .linearicons-trash2:before {
    content: "\e981";
  }
  .linearicons-trash3:before {
    content: "\e982";
  }
  .linearicons-broom:before {
    content: "\e983";
  }
  .linearicons-game:before {
    content: "\e984";
  }
  .linearicons-gamepad:before {
    content: "\e985";
  }
  .linearicons-joystick:before {
    content: "\e986";
  }
  .linearicons-dice:before {
    content: "\e987";
  }
  .linearicons-spades:before {
    content: "\e988";
  }
  .linearicons-diamonds:before {
    content: "\e989";
  }
  .linearicons-clubs:before {
    content: "\e98a";
  }
  .linearicons-hearts:before {
    content: "\e98b";
  }
  .linearicons-heart:before {
    content: "\e98c";
  }
  .linearicons-star:before {
    content: "\e98d";
  }
  .linearicons-star-half:before {
    content: "\e98e";
  }
  .linearicons-star-empty:before {
    content: "\e98f";
  }
  .linearicons-flag:before {
    content: "\e990";
  }
  .linearicons-flag2:before {
    content: "\e991";
  }
  .linearicons-flag3:before {
    content: "\e992";
  }
  .linearicons-mailbox-full:before {
    content: "\e993";
  }
  .linearicons-mailbox-empty:before {
    content: "\e994";
  }
  .linearicons-at-sign:before {
    content: "\e995";
  }
  .linearicons-envelope:before {
    content: "\e996";
  }
  .linearicons-envelope-open:before {
    content: "\e997";
  }
  .linearicons-paperclip:before {
    content: "\e998";
  }
  .linearicons-paper-plane:before {
    content: "\e999";
  }
  .linearicons-reply:before {
    content: "\e99a";
  }
  .linearicons-reply-all:before {
    content: "\e99b";
  }
  .linearicons-inbox:before {
    content: "\e99c";
  }
  .linearicons-inbox2:before {
    content: "\e99d";
  }
  .linearicons-outbox:before {
    content: "\e99e";
  }
  .linearicons-box:before {
    content: "\e99f";
  }
  .linearicons-archive:before {
    content: "\e9a0";
  }
  .linearicons-archive2:before {
    content: "\e9a1";
  }
  .linearicons-drawers:before {
    content: "\e9a2";
  }
  .linearicons-drawers2:before {
    content: "\e9a3";
  }
  .linearicons-drawers3:before {
    content: "\e9a4";
  }
  .linearicons-eye:before {
    content: "\e9a5";
  }
  .linearicons-eye-crossed:before {
    content: "\e9a6";
  }
  .linearicons-eye-plus:before {
    content: "\e9a7";
  }
  .linearicons-eye-minus:before {
    content: "\e9a8";
  }
  .linearicons-binoculars:before {
    content: "\e9a9";
  }
  .linearicons-binoculars2:before {
    content: "\e9aa";
  }
  .linearicons-hdd:before {
    content: "\e9ab";
  }
  .linearicons-hdd-down:before {
    content: "\e9ac";
  }
  .linearicons-hdd-up:before {
    content: "\e9ad";
  }
  .linearicons-floppy-disk:before {
    content: "\e9ae";
  }
  .linearicons-disc:before {
    content: "\e9af";
  }
  .linearicons-tape2:before {
    content: "\e9b0";
  }
  .linearicons-printer:before {
    content: "\e9b1";
  }
  .linearicons-shredder:before {
    content: "\e9b2";
  }
  .linearicons-file-empty:before {
    content: "\e9b3";
  }
  .linearicons-file-add:before {
    content: "\e9b4";
  }
  .linearicons-file-check:before {
    content: "\e9b5";
  }
  .linearicons-file-lock:before {
    content: "\e9b6";
  }
  .linearicons-files:before {
    content: "\e9b7";
  }
  .linearicons-copy:before {
    content: "\e9b8";
  }
  .linearicons-compare:before {
    content: "\e9b9";
  }
  .linearicons-folder:before {
    content: "\e9ba";
  }
  .linearicons-folder-search:before {
    content: "\e9bb";
  }
  .linearicons-folder-plus:before {
    content: "\e9bc";
  }
  .linearicons-folder-minus:before {
    content: "\e9bd";
  }
  .linearicons-folder-download:before {
    content: "\e9be";
  }
  .linearicons-folder-upload:before {
    content: "\e9bf";
  }
  .linearicons-folder-star:before {
    content: "\e9c0";
  }
  .linearicons-folder-heart:before {
    content: "\e9c1";
  }
  .linearicons-folder-user:before {
    content: "\e9c2";
  }
  .linearicons-folder-shared:before {
    content: "\e9c3";
  }
  .linearicons-folder-music:before {
    content: "\e9c4";
  }
  .linearicons-folder-picture:before {
    content: "\e9c5";
  }
  .linearicons-folder-film:before {
    content: "\e9c6";
  }
  .linearicons-scissors:before {
    content: "\e9c7";
  }
  .linearicons-paste:before {
    content: "\e9c8";
  }
  .linearicons-clipboard-empty:before {
    content: "\e9c9";
  }
  .linearicons-clipboard-pencil:before {
    content: "\e9ca";
  }
  .linearicons-clipboard-text:before {
    content: "\e9cb";
  }
  .linearicons-clipboard-check:before {
    content: "\e9cc";
  }
  .linearicons-clipboard-down:before {
    content: "\e9cd";
  }
  .linearicons-clipboard-left:before {
    content: "\e9ce";
  }
  .linearicons-clipboard-alert:before {
    content: "\e9cf";
  }
  .linearicons-clipboard-user:before {
    content: "\e9d0";
  }
  .linearicons-register:before {
    content: "\e9d1";
  }
  .linearicons-enter:before {
    content: "\e9d2";
  }
  .linearicons-exit:before {
    content: "\e9d3";
  }
  .linearicons-papers:before {
    content: "\e9d4";
  }
  .linearicons-news:before {
    content: "\e9d5";
  }
  .linearicons-reading:before {
    content: "\e9d6";
  }
  .linearicons-typewriter:before {
    content: "\e9d7";
  }
  .linearicons-document:before {
    content: "\e9d8";
  }
  .linearicons-document2:before {
    content: "\e9d9";
  }
  .linearicons-graduation-hat:before {
    content: "\e9da";
  }
  .linearicons-license:before {
    content: "\e9db";
  }
  .linearicons-license2:before {
    content: "\e9dc";
  }
  .linearicons-medal-empty:before {
    content: "\e9dd";
  }
  .linearicons-medal-first:before {
    content: "\e9de";
  }
  .linearicons-medal-second:before {
    content: "\e9df";
  }
  .linearicons-medal-third:before {
    content: "\e9e0";
  }
  .linearicons-podium:before {
    content: "\e9e1";
  }
  .linearicons-trophy:before {
    content: "\e9e2";
  }
  .linearicons-trophy2:before {
    content: "\e9e3";
  }
  .linearicons-music-note:before {
    content: "\e9e4";
  }
  .linearicons-music-note2:before {
    content: "\e9e5";
  }
  .linearicons-music-note3:before {
    content: "\e9e6";
  }
  .linearicons-playlist:before {
    content: "\e9e7";
  }
  .linearicons-playlist-add:before {
    content: "\e9e8";
  }
  .linearicons-guitar:before {
    content: "\e9e9";
  }
  .linearicons-trumpet:before {
    content: "\e9ea";
  }
  .linearicons-album:before {
    content: "\e9eb";
  }
  .linearicons-shuffle:before {
    content: "\e9ec";
  }
  .linearicons-repeat-one:before {
    content: "\e9ed";
  }
  .linearicons-repeat:before {
    content: "\e9ee";
  }
  .linearicons-headphones:before {
    content: "\e9ef";
  }
  .linearicons-headset:before {
    content: "\e9f0";
  }
  .linearicons-loudspeaker:before {
    content: "\e9f1";
  }
  .linearicons-equalizer:before {
    content: "\e9f2";
  }
  .linearicons-theater:before {
    content: "\e9f3";
  }
  .linearicons-3d-glasses:before {
    content: "\e9f4";
  }
  .linearicons-ticket:before {
    content: "\e9f5";
  }
  .linearicons-presentation:before {
    content: "\e9f6";
  }
  .linearicons-play:before {
    content: "\e9f7";
  }
  .linearicons-film-play:before {
    content: "\e9f8";
  }
  .linearicons-clapboard-play:before {
    content: "\e9f9";
  }
  .linearicons-media:before {
    content: "\e9fa";
  }
  .linearicons-film:before {
    content: "\e9fb";
  }
  .linearicons-film2:before {
    content: "\e9fc";
  }
  .linearicons-surveillance:before {
    content: "\e9fd";
  }
  .linearicons-surveillance2:before {
    content: "\e9fe";
  }
  .linearicons-camera:before {
    content: "\e9ff";
  }
  .linearicons-camera-crossed:before {
    content: "\ea00";
  }
  .linearicons-camera-play:before {
    content: "\ea01";
  }
  .linearicons-time-lapse:before {
    content: "\ea02";
  }
  .linearicons-record:before {
    content: "\ea03";
  }
  .linearicons-camera2:before {
    content: "\ea04";
  }
  .linearicons-camera-flip:before {
    content: "\ea05";
  }
  .linearicons-panorama:before {
    content: "\ea06";
  }
  .linearicons-time-lapse2:before {
    content: "\ea07";
  }
  .linearicons-shutter:before {
    content: "\ea08";
  }
  .linearicons-shutter2:before {
    content: "\ea09";
  }
  .linearicons-face-detection:before {
    content: "\ea0a";
  }
  .linearicons-flare:before {
    content: "\ea0b";
  }
  .linearicons-convex:before {
    content: "\ea0c";
  }
  .linearicons-concave:before {
    content: "\ea0d";
  }
  .linearicons-picture:before {
    content: "\ea0e";
  }
  .linearicons-picture2:before {
    content: "\ea0f";
  }
  .linearicons-picture3:before {
    content: "\ea10";
  }
  .linearicons-pictures:before {
    content: "\ea11";
  }
  .linearicons-book:before {
    content: "\ea12";
  }
  .linearicons-audio-book:before {
    content: "\ea13";
  }
  .linearicons-book2:before {
    content: "\ea14";
  }
  .linearicons-bookmark:before {
    content: "\ea15";
  }
  .linearicons-bookmark2:before {
    content: "\ea16";
  }
  .linearicons-label:before {
    content: "\ea17";
  }
  .linearicons-library:before {
    content: "\ea18";
  }
  .linearicons-library2:before {
    content: "\ea19";
  }
  .linearicons-contacts:before {
    content: "\ea1a";
  }
  .linearicons-profile:before {
    content: "\ea1b";
  }
  .linearicons-portrait:before {
    content: "\ea1c";
  }
  .linearicons-portrait2:before {
    content: "\ea1d";
  }
  .linearicons-user:before {
    content: "\ea1e";
  }
  .linearicons-user-plus:before {
    content: "\ea1f";
  }
  .linearicons-user-minus:before {
    content: "\ea20";
  }
  .linearicons-user-lock:before {
    content: "\ea21";
  }
  .linearicons-users:before {
    content: "\ea22";
  }
  .linearicons-users2:before {
    content: "\ea23";
  }
  .linearicons-users-plus:before {
    content: "\ea24";
  }
  .linearicons-users-minus:before {
    content: "\ea25";
  }
  .linearicons-group-work:before {
    content: "\ea26";
  }
  .linearicons-woman:before {
    content: "\ea27";
  }
  .linearicons-man:before {
    content: "\ea28";
  }
  .linearicons-baby:before {
    content: "\ea29";
  }
  .linearicons-baby2:before {
    content: "\ea2a";
  }
  .linearicons-baby3:before {
    content: "\ea2b";
  }
  .linearicons-baby-bottle:before {
    content: "\ea2c";
  }
  .linearicons-walk:before {
    content: "\ea2d";
  }
  .linearicons-hand-waving:before {
    content: "\ea2e";
  }
  .linearicons-jump:before {
    content: "\ea2f";
  }
  .linearicons-run:before {
    content: "\ea30";
  }
  .linearicons-woman2:before {
    content: "\ea31";
  }
  .linearicons-man2:before {
    content: "\ea32";
  }
  .linearicons-man-woman:before {
    content: "\ea33";
  }
  .linearicons-height:before {
    content: "\ea34";
  }
  .linearicons-weight:before {
    content: "\ea35";
  }
  .linearicons-scale:before {
    content: "\ea36";
  }
  .linearicons-button:before {
    content: "\ea37";
  }
  .linearicons-bow-tie:before {
    content: "\ea38";
  }
  .linearicons-tie:before {
    content: "\ea39";
  }
  .linearicons-socks:before {
    content: "\ea3a";
  }
  .linearicons-shoe:before {
    content: "\ea3b";
  }
  .linearicons-shoes:before {
    content: "\ea3c";
  }
  .linearicons-hat:before {
    content: "\ea3d";
  }
  .linearicons-pants:before {
    content: "\ea3e";
  }
  .linearicons-shorts:before {
    content: "\ea3f";
  }
  .linearicons-flip-flops:before {
    content: "\ea40";
  }
  .linearicons-shirt:before {
    content: "\ea41";
  }
  .linearicons-hanger:before {
    content: "\ea42";
  }
  .linearicons-laundry:before {
    content: "\ea43";
  }
  .linearicons-store:before {
    content: "\ea44";
  }
  .linearicons-haircut:before {
    content: "\ea45";
  }
  .linearicons-store-24:before {
    content: "\ea46";
  }
  .linearicons-barcode:before {
    content: "\ea47";
  }
  .linearicons-barcode2:before {
    content: "\ea48";
  }
  .linearicons-barcode3:before {
    content: "\ea49";
  }
  .linearicons-cashier:before {
    content: "\ea4a";
  }
  .linearicons-bag:before {
    content: "\ea4b";
  }
  .linearicons-bag2:before {
    content: "\ea4c";
  }
  .linearicons-cart:before {
    content: "\ea4d";
  }
  .linearicons-cart-empty:before {
    content: "\ea4e";
  }
  .linearicons-cart-full:before {
    content: "\ea4f";
  }
  .linearicons-cart-plus:before {
    content: "\ea50";
  }
  .linearicons-cart-plus2:before {
    content: "\ea51";
  }
  .linearicons-cart-add:before {
    content: "\ea52";
  }
  .linearicons-cart-remove:before {
    content: "\ea53";
  }
  .linearicons-cart-exchange:before {
    content: "\ea54";
  }
  .linearicons-tag:before {
    content: "\ea55";
  }
  .linearicons-tags:before {
    content: "\ea56";
  }
  .linearicons-receipt:before {
    content: "\ea57";
  }
  .linearicons-wallet:before {
    content: "\ea58";
  }
  .linearicons-credit-card:before {
    content: "\ea59";
  }
  .linearicons-cash-dollar:before {
    content: "\ea5a";
  }
  .linearicons-cash-euro:before {
    content: "\ea5b";
  }
  .linearicons-cash-pound:before {
    content: "\ea5c";
  }
  .linearicons-cash-yen:before {
    content: "\ea5d";
  }
  .linearicons-bag-dollar:before {
    content: "\ea5e";
  }
  .linearicons-bag-euro:before {
    content: "\ea5f";
  }
  .linearicons-bag-pound:before {
    content: "\ea60";
  }
  .linearicons-bag-yen:before {
    content: "\ea61";
  }
  .linearicons-coin-dollar:before {
    content: "\ea62";
  }
  .linearicons-coin-euro:before {
    content: "\ea63";
  }
  .linearicons-coin-pound:before {
    content: "\ea64";
  }
  .linearicons-coin-yen:before {
    content: "\ea65";
  }
  .linearicons-calculator:before {
    content: "\ea66";
  }
  .linearicons-calculator2:before {
    content: "\ea67";
  }
  .linearicons-abacus:before {
    content: "\ea68";
  }
  .linearicons-vault:before {
    content: "\ea69";
  }
  .linearicons-telephone:before {
    content: "\ea6a";
  }
  .linearicons-phone-lock:before {
    content: "\ea6b";
  }
  .linearicons-phone-wave:before {
    content: "\ea6c";
  }
  .linearicons-phone-pause:before {
    content: "\ea6d";
  }
  .linearicons-phone-outgoing:before {
    content: "\ea6e";
  }
  .linearicons-phone-incoming:before {
    content: "\ea6f";
  }
  .linearicons-phone-in-out:before {
    content: "\ea70";
  }
  .linearicons-phone-error:before {
    content: "\ea71";
  }
  .linearicons-phone-sip:before {
    content: "\ea72";
  }
  .linearicons-phone-plus:before {
    content: "\ea73";
  }
  .linearicons-phone-minus:before {
    content: "\ea74";
  }
  .linearicons-voicemail:before {
    content: "\ea75";
  }
  .linearicons-dial:before {
    content: "\ea76";
  }
  .linearicons-telephone2:before {
    content: "\ea77";
  }
  .linearicons-pushpin:before {
    content: "\ea78";
  }
  .linearicons-pushpin2:before {
    content: "\ea79";
  }
  .linearicons-map-marker:before {
    content: "\ea7a";
  }
  .linearicons-map-marker-user:before {
    content: "\ea7b";
  }
  .linearicons-map-marker-down:before {
    content: "\ea7c";
  }
  .linearicons-map-marker-check:before {
    content: "\ea7d";
  }
  .linearicons-map-marker-crossed:before {
    content: "\ea7e";
  }
  .linearicons-radar:before {
    content: "\ea7f";
  }
  .linearicons-compass2:before {
    content: "\ea80";
  }
  .linearicons-map:before {
    content: "\ea81";
  }
  .linearicons-map2:before {
    content: "\ea82";
  }
  .linearicons-location:before {
    content: "\ea83";
  }
  .linearicons-road-sign:before {
    content: "\ea84";
  }
  .linearicons-calendar-empty:before {
    content: "\ea85";
  }
  .linearicons-calendar-check:before {
    content: "\ea86";
  }
  .linearicons-calendar-cross:before {
    content: "\ea87";
  }
  .linearicons-calendar-31:before {
    content: "\ea88";
  }
  .linearicons-calendar-full:before {
    content: "\ea89";
  }
  .linearicons-calendar-insert:before {
    content: "\ea8a";
  }
  .linearicons-calendar-text:before {
    content: "\ea8b";
  }
  .linearicons-calendar-user:before {
    content: "\ea8c";
  }
  .linearicons-mouse:before {
    content: "\ea8d";
  }
  .linearicons-mouse-left:before {
    content: "\ea8e";
  }
  .linearicons-mouse-right:before {
    content: "\ea8f";
  }
  .linearicons-mouse-both:before {
    content: "\ea90";
  }
  .linearicons-keyboard:before {
    content: "\ea91";
  }
  .linearicons-keyboard-up:before {
    content: "\ea92";
  }
  .linearicons-keyboard-down:before {
    content: "\ea93";
  }
  .linearicons-delete:before {
    content: "\ea94";
  }
  .linearicons-spell-check:before {
    content: "\ea95";
  }
  .linearicons-escape:before {
    content: "\ea96";
  }
  .linearicons-enter2:before {
    content: "\ea97";
  }
  .linearicons-screen:before {
    content: "\ea98";
  }
  .linearicons-aspect-ratio:before {
    content: "\ea99";
  }
  .linearicons-signal:before {
    content: "\ea9a";
  }
  .linearicons-signal-lock:before {
    content: "\ea9b";
  }
  .linearicons-signal-80:before {
    content: "\ea9c";
  }
  .linearicons-signal-60:before {
    content: "\ea9d";
  }
  .linearicons-signal-40:before {
    content: "\ea9e";
  }
  .linearicons-signal-20:before {
    content: "\ea9f";
  }
  .linearicons-signal-0:before {
    content: "\eaa0";
  }
  .linearicons-signal-blocked:before {
    content: "\eaa1";
  }
  .linearicons-sim:before {
    content: "\eaa2";
  }
  .linearicons-flash-memory:before {
    content: "\eaa3";
  }
  .linearicons-usb-drive:before {
    content: "\eaa4";
  }
  .linearicons-phone:before {
    content: "\eaa5";
  }
  .linearicons-smartphone:before {
    content: "\eaa6";
  }
  .linearicons-smartphone-notification:before {
    content: "\eaa7";
  }
  .linearicons-smartphone-vibration:before {
    content: "\eaa8";
  }
  .linearicons-smartphone-embed:before {
    content: "\eaa9";
  }
  .linearicons-smartphone-waves:before {
    content: "\eaaa";
  }
  .linearicons-tablet:before {
    content: "\eaab";
  }
  .linearicons-tablet2:before {
    content: "\eaac";
  }
  .linearicons-laptop:before {
    content: "\eaad";
  }
  .linearicons-laptop-phone:before {
    content: "\eaae";
  }
  .linearicons-desktop:before {
    content: "\eaaf";
  }
  .linearicons-launch:before {
    content: "\eab0";
  }
  .linearicons-new-tab:before {
    content: "\eab1";
  }
  .linearicons-window:before {
    content: "\eab2";
  }
  .linearicons-cable:before {
    content: "\eab3";
  }
  .linearicons-cable2:before {
    content: "\eab4";
  }
  .linearicons-tv:before {
    content: "\eab5";
  }
  .linearicons-radio:before {
    content: "\eab6";
  }
  .linearicons-remote-control:before {
    content: "\eab7";
  }
  .linearicons-power-switch:before {
    content: "\eab8";
  }
  .linearicons-power:before {
    content: "\eab9";
  }
  .linearicons-power-crossed:before {
    content: "\eaba";
  }
  .linearicons-flash-auto:before {
    content: "\eabb";
  }
  .linearicons-lamp:before {
    content: "\eabc";
  }
  .linearicons-flashlight:before {
    content: "\eabd";
  }
  .linearicons-lampshade:before {
    content: "\eabe";
  }
  .linearicons-cord:before {
    content: "\eabf";
  }
  .linearicons-outlet:before {
    content: "\eac0";
  }
  .linearicons-battery-power:before {
    content: "\eac1";
  }
  .linearicons-battery-empty:before {
    content: "\eac2";
  }
  .linearicons-battery-alert:before {
    content: "\eac3";
  }
  .linearicons-battery-error:before {
    content: "\eac4";
  }
  .linearicons-battery-low1:before {
    content: "\eac5";
  }
  .linearicons-battery-low2:before {
    content: "\eac6";
  }
  .linearicons-battery-low3:before {
    content: "\eac7";
  }
  .linearicons-battery-mid1:before {
    content: "\eac8";
  }
  .linearicons-battery-mid2:before {
    content: "\eac9";
  }
  .linearicons-battery-mid3:before {
    content: "\eaca";
  }
  .linearicons-battery-full:before {
    content: "\eacb";
  }
  .linearicons-battery-charging:before {
    content: "\eacc";
  }
  .linearicons-battery-charging2:before {
    content: "\eacd";
  }
  .linearicons-battery-charging3:before {
    content: "\eace";
  }
  .linearicons-battery-charging4:before {
    content: "\eacf";
  }
  .linearicons-battery-charging5:before {
    content: "\ead0";
  }
  .linearicons-battery-charging6:before {
    content: "\ead1";
  }
  .linearicons-battery-charging7:before {
    content: "\ead2";
  }
  .linearicons-chip:before {
    content: "\ead3";
  }
  .linearicons-chip-x64:before {
    content: "\ead4";
  }
  .linearicons-chip-x86:before {
    content: "\ead5";
  }
  .linearicons-bubble:before {
    content: "\ead6";
  }
  .linearicons-bubbles:before {
    content: "\ead7";
  }
  .linearicons-bubble-dots:before {
    content: "\ead8";
  }
  .linearicons-bubble-alert:before {
    content: "\ead9";
  }
  .linearicons-bubble-question:before {
    content: "\eada";
  }
  .linearicons-bubble-text:before {
    content: "\eadb";
  }
  .linearicons-bubble-pencil:before {
    content: "\eadc";
  }
  .linearicons-bubble-picture:before {
    content: "\eadd";
  }
  .linearicons-bubble-video:before {
    content: "\eade";
  }
  .linearicons-bubble-user:before {
    content: "\eadf";
  }
  .linearicons-bubble-quote:before {
    content: "\eae0";
  }
  .linearicons-bubble-heart:before {
    content: "\eae1";
  }
  .linearicons-bubble-emoticon:before {
    content: "\eae2";
  }
  .linearicons-bubble-attachment:before {
    content: "\eae3";
  }
  .linearicons-phone-bubble:before {
    content: "\eae4";
  }
  .linearicons-quote-open:before {
    content: "\eae5";
  }
  .linearicons-quote-close:before {
    content: "\eae6";
  }
  .linearicons-dna:before {
    content: "\eae7";
  }
  .linearicons-heart-pulse:before {
    content: "\eae8";
  }
  .linearicons-pulse:before {
    content: "\eae9";
  }
  .linearicons-syringe:before {
    content: "\eaea";
  }
  .linearicons-pills:before {
    content: "\eaeb";
  }
  .linearicons-first-aid:before {
    content: "\eaec";
  }
  .linearicons-lifebuoy:before {
    content: "\eaed";
  }
  .linearicons-bandage:before {
    content: "\eaee";
  }
  .linearicons-bandages:before {
    content: "\eaef";
  }
  .linearicons-thermometer:before {
    content: "\eaf0";
  }
  .linearicons-microscope:before {
    content: "\eaf1";
  }
  .linearicons-brain:before {
    content: "\eaf2";
  }
  .linearicons-beaker:before {
    content: "\eaf3";
  }
  .linearicons-skull:before {
    content: "\eaf4";
  }
  .linearicons-bone:before {
    content: "\eaf5";
  }
  .linearicons-construction:before {
    content: "\eaf6";
  }
  .linearicons-construction-cone:before {
    content: "\eaf7";
  }
  .linearicons-pie-chart:before {
    content: "\eaf8";
  }
  .linearicons-pie-chart2:before {
    content: "\eaf9";
  }
  .linearicons-graph:before {
    content: "\eafa";
  }
  .linearicons-chart-growth:before {
    content: "\eafb";
  }
  .linearicons-chart-bars:before {
    content: "\eafc";
  }
  .linearicons-chart-settings:before {
    content: "\eafd";
  }
  .linearicons-cake:before {
    content: "\eafe";
  }
  .linearicons-gift:before {
    content: "\eaff";
  }
  .linearicons-balloon:before {
    content: "\eb00";
  }
  .linearicons-rank:before {
    content: "\eb01";
  }
  .linearicons-rank2:before {
    content: "\eb02";
  }
  .linearicons-rank3:before {
    content: "\eb03";
  }
  .linearicons-crown:before {
    content: "\eb04";
  }
  .linearicons-lotus:before {
    content: "\eb05";
  }
  .linearicons-diamond:before {
    content: "\eb06";
  }
  .linearicons-diamond2:before {
    content: "\eb07";
  }
  .linearicons-diamond3:before {
    content: "\eb08";
  }
  .linearicons-diamond4:before {
    content: "\eb09";
  }
  .linearicons-linearicons:before {
    content: "\eb0a";
  }
  .linearicons-teacup:before {
    content: "\eb0b";
  }
  .linearicons-teapot:before {
    content: "\eb0c";
  }
  .linearicons-glass:before {
    content: "\eb0d";
  }
  .linearicons-bottle2:before {
    content: "\eb0e";
  }
  .linearicons-glass-cocktail:before {
    content: "\eb0f";
  }
  .linearicons-glass2:before {
    content: "\eb10";
  }
  .linearicons-dinner:before {
    content: "\eb11";
  }
  .linearicons-dinner2:before {
    content: "\eb12";
  }
  .linearicons-chef:before {
    content: "\eb13";
  }
  .linearicons-scale2:before {
    content: "\eb14";
  }
  .linearicons-egg:before {
    content: "\eb15";
  }
  .linearicons-egg2:before {
    content: "\eb16";
  }
  .linearicons-eggs:before {
    content: "\eb17";
  }
  .linearicons-platter:before {
    content: "\eb18";
  }
  .linearicons-steak:before {
    content: "\eb19";
  }
  .linearicons-hamburger:before {
    content: "\eb1a";
  }
  .linearicons-hotdog:before {
    content: "\eb1b";
  }
  .linearicons-pizza:before {
    content: "\eb1c";
  }
  .linearicons-sausage:before {
    content: "\eb1d";
  }
  .linearicons-chicken:before {
    content: "\eb1e";
  }
  .linearicons-fish:before {
    content: "\eb1f";
  }
  .linearicons-carrot:before {
    content: "\eb20";
  }
  .linearicons-cheese:before {
    content: "\eb21";
  }
  .linearicons-bread:before {
    content: "\eb22";
  }
  .linearicons-ice-cream:before {
    content: "\eb23";
  }
  .linearicons-ice-cream2:before {
    content: "\eb24";
  }
  .linearicons-candy:before {
    content: "\eb25";
  }
  .linearicons-lollipop:before {
    content: "\eb26";
  }
  .linearicons-coffee-bean:before {
    content: "\eb27";
  }
  .linearicons-coffee-cup:before {
    content: "\eb28";
  }
  .linearicons-cherry:before {
    content: "\eb29";
  }
  .linearicons-grapes:before {
    content: "\eb2a";
  }
  .linearicons-citrus:before {
    content: "\eb2b";
  }
  .linearicons-apple:before {
    content: "\eb2c";
  }
  .linearicons-leaf:before {
    content: "\eb2d";
  }
  .linearicons-landscape:before {
    content: "\eb2e";
  }
  .linearicons-pine-tree:before {
    content: "\eb2f";
  }
  .linearicons-tree:before {
    content: "\eb30";
  }
  .linearicons-cactus:before {
    content: "\eb31";
  }
  .linearicons-paw:before {
    content: "\eb32";
  }
  .linearicons-footprint:before {
    content: "\eb33";
  }
  .linearicons-speed-slow:before {
    content: "\eb34";
  }
  .linearicons-speed-medium:before {
    content: "\eb35";
  }
  .linearicons-speed-fast:before {
    content: "\eb36";
  }
  .linearicons-rocket:before {
    content: "\eb37";
  }
  .linearicons-hammer2:before {
    content: "\eb38";
  }
  .linearicons-balance:before {
    content: "\eb39";
  }
  .linearicons-briefcase:before {
    content: "\eb3a";
  }
  .linearicons-luggage-weight:before {
    content: "\eb3b";
  }
  .linearicons-dolly:before {
    content: "\eb3c";
  }
  .linearicons-plane:before {
    content: "\eb3d";
  }
  .linearicons-plane-crossed:before {
    content: "\eb3e";
  }
  .linearicons-helicopter:before {
    content: "\eb3f";
  }
  .linearicons-traffic-lights:before {
    content: "\eb40";
  }
  .linearicons-siren:before {
    content: "\eb41";
  }
  .linearicons-road:before {
    content: "\eb42";
  }
  .linearicons-engine:before {
    content: "\eb43";
  }
  .linearicons-oil-pressure:before {
    content: "\eb44";
  }
  .linearicons-coolant-temperature:before {
    content: "\eb45";
  }
  .linearicons-car-battery:before {
    content: "\eb46";
  }
  .linearicons-gas:before {
    content: "\eb47";
  }
  .linearicons-gallon:before {
    content: "\eb48";
  }
  .linearicons-transmission:before {
    content: "\eb49";
  }
  .linearicons-car:before {
    content: "\eb4a";
  }
  .linearicons-car-wash:before {
    content: "\eb4b";
  }
  .linearicons-car-wash2:before {
    content: "\eb4c";
  }
  .linearicons-bus:before {
    content: "\eb4d";
  }
  .linearicons-bus2:before {
    content: "\eb4e";
  }
  .linearicons-car2:before {
    content: "\eb4f";
  }
  .linearicons-parking:before {
    content: "\eb50";
  }
  .linearicons-car-lock:before {
    content: "\eb51";
  }
  .linearicons-taxi:before {
    content: "\eb52";
  }
  .linearicons-car-siren:before {
    content: "\eb53";
  }
  .linearicons-car-wash3:before {
    content: "\eb54";
  }
  .linearicons-car-wash4:before {
    content: "\eb55";
  }
  .linearicons-ambulance:before {
    content: "\eb56";
  }
  .linearicons-truck:before {
    content: "\eb57";
  }
  .linearicons-trailer:before {
    content: "\eb58";
  }
  .linearicons-scale-truck:before {
    content: "\eb59";
  }
  .linearicons-train:before {
    content: "\eb5a";
  }
  .linearicons-ship:before {
    content: "\eb5b";
  }
  .linearicons-ship2:before {
    content: "\eb5c";
  }
  .linearicons-anchor:before {
    content: "\eb5d";
  }
  .linearicons-boat:before {
    content: "\eb5e";
  }
  .linearicons-bicycle:before {
    content: "\eb5f";
  }
  .linearicons-bicycle2:before {
    content: "\eb60";
  }
  .linearicons-dumbbell:before {
    content: "\eb61";
  }
  .linearicons-bench-press:before {
    content: "\eb62";
  }
  .linearicons-swim:before {
    content: "\eb63";
  }
  .linearicons-football:before {
    content: "\eb64";
  }
  .linearicons-baseball-bat:before {
    content: "\eb65";
  }
  .linearicons-baseball:before {
    content: "\eb66";
  }
  .linearicons-tennis:before {
    content: "\eb67";
  }
  .linearicons-tennis2:before {
    content: "\eb68";
  }
  .linearicons-ping-pong:before {
    content: "\eb69";
  }
  .linearicons-hockey:before {
    content: "\eb6a";
  }
  .linearicons-8ball:before {
    content: "\eb6b";
  }
  .linearicons-bowling:before {
    content: "\eb6c";
  }
  .linearicons-bowling-pins:before {
    content: "\eb6d";
  }
  .linearicons-golf:before {
    content: "\eb6e";
  }
  .linearicons-golf2:before {
    content: "\eb6f";
  }
  .linearicons-archery:before {
    content: "\eb70";
  }
  .linearicons-slingshot:before {
    content: "\eb71";
  }
  .linearicons-soccer:before {
    content: "\eb72";
  }
  .linearicons-basketball:before {
    content: "\eb73";
  }
  .linearicons-cube:before {
    content: "\eb74";
  }
  .linearicons-3d-rotate:before {
    content: "\eb75";
  }
  .linearicons-puzzle:before {
    content: "\eb76";
  }
  .linearicons-glasses:before {
    content: "\eb77";
  }
  .linearicons-glasses2:before {
    content: "\eb78";
  }
  .linearicons-accessibility:before {
    content: "\eb79";
  }
  .linearicons-wheelchair:before {
    content: "\eb7a";
  }
  .linearicons-wall:before {
    content: "\eb7b";
  }
  .linearicons-fence:before {
    content: "\eb7c";
  }
  .linearicons-wall2:before {
    content: "\eb7d";
  }
  .linearicons-icons:before {
    content: "\eb7e";
  }
  .linearicons-resize-handle:before {
    content: "\eb7f";
  }
  .linearicons-icons2:before {
    content: "\eb80";
  }
  .linearicons-select:before {
    content: "\eb81";
  }
  .linearicons-select2:before {
    content: "\eb82";
  }
  .linearicons-site-map:before {
    content: "\eb83";
  }
  .linearicons-earth:before {
    content: "\eb84";
  }
  .linearicons-earth-lock:before {
    content: "\eb85";
  }
  .linearicons-network:before {
    content: "\eb86";
  }
  .linearicons-network-lock:before {
    content: "\eb87";
  }
  .linearicons-planet:before {
    content: "\eb88";
  }
  .linearicons-happy:before {
    content: "\eb89";
  }
  .linearicons-smile:before {
    content: "\eb8a";
  }
  .linearicons-grin:before {
    content: "\eb8b";
  }
  .linearicons-tongue:before {
    content: "\eb8c";
  }
  .linearicons-sad:before {
    content: "\eb8d";
  }
  .linearicons-wink:before {
    content: "\eb8e";
  }
  .linearicons-dream:before {
    content: "\eb8f";
  }
  .linearicons-shocked:before {
    content: "\eb90";
  }
  .linearicons-shocked2:before {
    content: "\eb91";
  }
  .linearicons-tongue2:before {
    content: "\eb92";
  }
  .linearicons-neutral:before {
    content: "\eb93";
  }
  .linearicons-happy-grin:before {
    content: "\eb94";
  }
  .linearicons-cool:before {
    content: "\eb95";
  }
  .linearicons-mad:before {
    content: "\eb96";
  }
  .linearicons-grin-evil:before {
    content: "\eb97";
  }
  .linearicons-evil:before {
    content: "\eb98";
  }
  .linearicons-wow:before {
    content: "\eb99";
  }
  .linearicons-annoyed:before {
    content: "\eb9a";
  }
  .linearicons-wondering:before {
    content: "\eb9b";
  }
  .linearicons-confused:before {
    content: "\eb9c";
  }
  .linearicons-zipped:before {
    content: "\eb9d";
  }
  .linearicons-grumpy:before {
    content: "\eb9e";
  }
  .linearicons-mustache:before {
    content: "\eb9f";
  }
  .linearicons-tombstone-hipster:before {
    content: "\eba0";
  }
  .linearicons-tombstone:before {
    content: "\eba1";
  }
  .linearicons-ghost:before {
    content: "\eba2";
  }
  .linearicons-ghost-hipster:before {
    content: "\eba3";
  }
  .linearicons-halloween:before {
    content: "\eba4";
  }
  .linearicons-christmas:before {
    content: "\eba5";
  }
  .linearicons-easter-egg:before {
    content: "\eba6";
  }
  .linearicons-mustache2:before {
    content: "\eba7";
  }
  .linearicons-mustache-glasses:before {
    content: "\eba8";
  }
  .linearicons-pipe:before {
    content: "\eba9";
  }
  .linearicons-alarm:before {
    content: "\ebaa";
  }
  .linearicons-alarm-add:before {
    content: "\ebab";
  }
  .linearicons-alarm-snooze:before {
    content: "\ebac";
  }
  .linearicons-alarm-ringing:before {
    content: "\ebad";
  }
  .linearicons-bullhorn:before {
    content: "\ebae";
  }
  .linearicons-hearing:before {
    content: "\ebaf";
  }
  .linearicons-volume-high:before {
    content: "\ebb0";
  }
  .linearicons-volume-medium:before {
    content: "\ebb1";
  }
  .linearicons-volume-low:before {
    content: "\ebb2";
  }
  .linearicons-volume:before {
    content: "\ebb3";
  }
  .linearicons-mute:before {
    content: "\ebb4";
  }
  .linearicons-lan:before {
    content: "\ebb5";
  }
  .linearicons-lan2:before {
    content: "\ebb6";
  }
  .linearicons-wifi:before {
    content: "\ebb7";
  }
  .linearicons-wifi-lock:before {
    content: "\ebb8";
  }
  .linearicons-wifi-blocked:before {
    content: "\ebb9";
  }
  .linearicons-wifi-mid:before {
    content: "\ebba";
  }
  .linearicons-wifi-low:before {
    content: "\ebbb";
  }
  .linearicons-wifi-low2:before {
    content: "\ebbc";
  }
  .linearicons-wifi-alert:before {
    content: "\ebbd";
  }
  .linearicons-wifi-alert-mid:before {
    content: "\ebbe";
  }
  .linearicons-wifi-alert-low:before {
    content: "\ebbf";
  }
  .linearicons-wifi-alert-low2:before {
    content: "\ebc0";
  }
  .linearicons-stream:before {
    content: "\ebc1";
  }
  .linearicons-stream-check:before {
    content: "\ebc2";
  }
  .linearicons-stream-error:before {
    content: "\ebc3";
  }
  .linearicons-stream-alert:before {
    content: "\ebc4";
  }
  .linearicons-communication:before {
    content: "\ebc5";
  }
  .linearicons-communication-crossed:before {
    content: "\ebc6";
  }
  .linearicons-broadcast:before {
    content: "\ebc7";
  }
  .linearicons-antenna:before {
    content: "\ebc8";
  }
  .linearicons-satellite:before {
    content: "\ebc9";
  }
  .linearicons-satellite2:before {
    content: "\ebca";
  }
  .linearicons-mic:before {
    content: "\ebcb";
  }
  .linearicons-mic-mute:before {
    content: "\ebcc";
  }
  .linearicons-mic2:before {
    content: "\ebcd";
  }
  .linearicons-spotlights:before {
    content: "\ebce";
  }
  .linearicons-hourglass:before {
    content: "\ebcf";
  }
  .linearicons-loading:before {
    content: "\ebd0";
  }
  .linearicons-loading2:before {
    content: "\ebd1";
  }
  .linearicons-loading3:before {
    content: "\ebd2";
  }
  .linearicons-refresh:before {
    content: "\ebd3";
  }
  .linearicons-refresh2:before {
    content: "\ebd4";
  }
  .linearicons-undo:before {
    content: "\ebd5";
  }
  .linearicons-redo:before {
    content: "\ebd6";
  }
  .linearicons-jump2:before {
    content: "\ebd7";
  }
  .linearicons-undo2:before {
    content: "\ebd8";
  }
  .linearicons-redo2:before {
    content: "\ebd9";
  }
  .linearicons-sync:before {
    content: "\ebda";
  }
  .linearicons-repeat-one2:before {
    content: "\ebdb";
  }
  .linearicons-sync-crossed:before {
    content: "\ebdc";
  }
  .linearicons-sync2:before {
    content: "\ebdd";
  }
  .linearicons-repeat-one3:before {
    content: "\ebde";
  }
  .linearicons-sync-crossed2:before {
    content: "\ebdf";
  }
  .linearicons-return:before {
    content: "\ebe0";
  }
  .linearicons-return2:before {
    content: "\ebe1";
  }
  .linearicons-refund:before {
    content: "\ebe2";
  }
  .linearicons-history:before {
    content: "\ebe3";
  }
  .linearicons-history2:before {
    content: "\ebe4";
  }
  .linearicons-self-timer:before {
    content: "\ebe5";
  }
  .linearicons-clock:before {
    content: "\ebe6";
  }
  .linearicons-clock2:before {
    content: "\ebe7";
  }
  .linearicons-clock3:before {
    content: "\ebe8";
  }
  .linearicons-watch:before {
    content: "\ebe9";
  }
  .linearicons-alarm2:before {
    content: "\ebea";
  }
  .linearicons-alarm-add2:before {
    content: "\ebeb";
  }
  .linearicons-alarm-remove:before {
    content: "\ebec";
  }
  .linearicons-alarm-check:before {
    content: "\ebed";
  }
  .linearicons-alarm-error:before {
    content: "\ebee";
  }
  .linearicons-timer:before {
    content: "\ebef";
  }
  .linearicons-timer-crossed:before {
    content: "\ebf0";
  }
  .linearicons-timer2:before {
    content: "\ebf1";
  }
  .linearicons-timer-crossed2:before {
    content: "\ebf2";
  }
  .linearicons-download:before {
    content: "\ebf3";
  }
  .linearicons-upload:before {
    content: "\ebf4";
  }
  .linearicons-download2:before {
    content: "\ebf5";
  }
  .linearicons-upload2:before {
    content: "\ebf6";
  }
  .linearicons-enter-up:before {
    content: "\ebf7";
  }
  .linearicons-enter-down:before {
    content: "\ebf8";
  }
  .linearicons-enter-left:before {
    content: "\ebf9";
  }
  .linearicons-enter-right:before {
    content: "\ebfa";
  }
  .linearicons-exit-up:before {
    content: "\ebfb";
  }
  .linearicons-exit-down:before {
    content: "\ebfc";
  }
  .linearicons-exit-left:before {
    content: "\ebfd";
  }
  .linearicons-exit-right:before {
    content: "\ebfe";
  }
  .linearicons-enter-up2:before {
    content: "\ebff";
  }
  .linearicons-enter-down2:before {
    content: "\ec00";
  }
  .linearicons-enter-vertical:before {
    content: "\ec01";
  }
  .linearicons-enter-left2:before {
    content: "\ec02";
  }
  .linearicons-enter-right2:before {
    content: "\ec03";
  }
  .linearicons-enter-horizontal:before {
    content: "\ec04";
  }
  .linearicons-exit-up2:before {
    content: "\ec05";
  }
  .linearicons-exit-down2:before {
    content: "\ec06";
  }
  .linearicons-exit-left2:before {
    content: "\ec07";
  }
  .linearicons-exit-right2:before {
    content: "\ec08";
  }
  .linearicons-cli:before {
    content: "\ec09";
  }
  .linearicons-bug:before {
    content: "\ec0a";
  }
  .linearicons-code:before {
    content: "\ec0b";
  }
  .linearicons-file-code:before {
    content: "\ec0c";
  }
  .linearicons-file-image:before {
    content: "\ec0d";
  }
  .linearicons-file-zip:before {
    content: "\ec0e";
  }
  .linearicons-file-audio:before {
    content: "\ec0f";
  }
  .linearicons-file-video:before {
    content: "\ec10";
  }
  .linearicons-file-preview:before {
    content: "\ec11";
  }
  .linearicons-file-charts:before {
    content: "\ec12";
  }
  .linearicons-file-stats:before {
    content: "\ec13";
  }
  .linearicons-file-spreadsheet:before {
    content: "\ec14";
  }
  .linearicons-link:before {
    content: "\ec15";
  }
  .linearicons-unlink:before {
    content: "\ec16";
  }
  .linearicons-link2:before {
    content: "\ec17";
  }
  .linearicons-unlink2:before {
    content: "\ec18";
  }
  .linearicons-thumbs-up:before {
    content: "\ec19";
  }
  .linearicons-thumbs-down:before {
    content: "\ec1a";
  }
  .linearicons-thumbs-up2:before {
    content: "\ec1b";
  }
  .linearicons-thumbs-down2:before {
    content: "\ec1c";
  }
  .linearicons-thumbs-up3:before {
    content: "\ec1d";
  }
  .linearicons-thumbs-down3:before {
    content: "\ec1e";
  }
  .linearicons-share:before {
    content: "\ec1f";
  }
  .linearicons-share2:before {
    content: "\ec20";
  }
  .linearicons-share3:before {
    content: "\ec21";
  }
  .linearicons-magnifier:before {
    content: "\ec22";
  }
  .linearicons-file-search:before {
    content: "\ec23";
  }
  .linearicons-find-replace:before {
    content: "\ec24";
  }
  .linearicons-zoom-in:before {
    content: "\ec25";
  }
  .linearicons-zoom-out:before {
    content: "\ec26";
  }
  .linearicons-loupe:before {
    content: "\ec27";
  }
  .linearicons-loupe-zoom-in:before {
    content: "\ec28";
  }
  .linearicons-loupe-zoom-out:before {
    content: "\ec29";
  }
  .linearicons-cross:before {
    content: "\ec2a";
  }
  .linearicons-menu:before {
    content: "\ec2b";
  }
  .linearicons-list:before {
    content: "\ec2c";
  }
  .linearicons-list2:before {
    content: "\ec2d";
  }
  .linearicons-list3:before {
    content: "\ec2e";
  }
  .linearicons-menu2:before {
    content: "\ec2f";
  }
  .linearicons-list4:before {
    content: "\ec30";
  }
  .linearicons-menu3:before {
    content: "\ec31";
  }
  .linearicons-exclamation:before {
    content: "\ec32";
  }
  .linearicons-question:before {
    content: "\ec33";
  }
  .linearicons-check:before {
    content: "\ec34";
  }
  .linearicons-cross2:before {
    content: "\ec35";
  }
  .linearicons-plus:before {
    content: "\ec36";
  }
  .linearicons-minus:before {
    content: "\ec37";
  }
  .linearicons-percent:before {
    content: "\ec38";
  }
  .linearicons-chevron-up:before {
    content: "\ec39";
  }
  .linearicons-chevron-down:before {
    content: "\ec3a";
  }
  .linearicons-chevron-left:before {
    content: "\ec3b";
  }
  .linearicons-chevron-right:before {
    content: "\ec3c";
  }
  .linearicons-chevrons-expand-vertical:before {
    content: "\ec3d";
  }
  .linearicons-chevrons-expand-horizontal:before {
    content: "\ec3e";
  }
  .linearicons-chevrons-contract-vertical:before {
    content: "\ec3f";
  }
  .linearicons-chevrons-contract-horizontal:before {
    content: "\ec40";
  }
  .linearicons-arrow-up:before {
    content: "\ec41";
  }
  .linearicons-arrow-down:before {
    content: "\ec42";
  }
  .linearicons-arrow-left:before {
    content: "\ec43";
  }
  .linearicons-arrow-right:before {
    content: "\ec44";
  }
  .linearicons-arrow-up-right:before {
    content: "\ec45";
  }
  .linearicons-arrows-merge:before {
    content: "\ec46";
  }
  .linearicons-arrows-split:before {
    content: "\ec47";
  }
  .linearicons-arrow-divert:before {
    content: "\ec48";
  }
  .linearicons-arrow-return:before {
    content: "\ec49";
  }
  .linearicons-expand:before {
    content: "\ec4a";
  }
  .linearicons-contract:before {
    content: "\ec4b";
  }
  .linearicons-expand2:before {
    content: "\ec4c";
  }
  .linearicons-contract2:before {
    content: "\ec4d";
  }
  .linearicons-move:before {
    content: "\ec4e";
  }
  .linearicons-tab:before {
    content: "\ec4f";
  }
  .linearicons-arrow-wave:before {
    content: "\ec50";
  }
  .linearicons-expand3:before {
    content: "\ec51";
  }
  .linearicons-expand4:before {
    content: "\ec52";
  }
  .linearicons-contract3:before {
    content: "\ec53";
  }
  .linearicons-notification:before {
    content: "\ec54";
  }
  .linearicons-warning:before {
    content: "\ec55";
  }
  .linearicons-notification-circle:before {
    content: "\ec56";
  }
  .linearicons-question-circle:before {
    content: "\ec57";
  }
  .linearicons-menu-circle:before {
    content: "\ec58";
  }
  .linearicons-checkmark-circle:before {
    content: "\ec59";
  }
  .linearicons-cross-circle:before {
    content: "\ec5a";
  }
  .linearicons-plus-circle:before {
    content: "\ec5b";
  }
  .linearicons-circle-minus:before {
    content: "\ec5c";
  }
  .linearicons-percent-circle:before {
    content: "\ec5d";
  }
  .linearicons-arrow-up-circle:before {
    content: "\ec5e";
  }
  .linearicons-arrow-down-circle:before {
    content: "\ec5f";
  }
  .linearicons-arrow-left-circle:before {
    content: "\ec60";
  }
  .linearicons-arrow-right-circle:before {
    content: "\ec61";
  }
  .linearicons-chevron-up-circle:before {
    content: "\ec62";
  }
  .linearicons-chevron-down-circle:before {
    content: "\ec63";
  }
  .linearicons-chevron-left-circle:before {
    content: "\ec64";
  }
  .linearicons-chevron-right-circle:before {
    content: "\ec65";
  }
  .linearicons-backward-circle:before {
    content: "\ec66";
  }
  .linearicons-first-circle:before {
    content: "\ec67";
  }
  .linearicons-previous-circle:before {
    content: "\ec68";
  }
  .linearicons-stop-circle:before {
    content: "\ec69";
  }
  .linearicons-play-circle:before {
    content: "\ec6a";
  }
  .linearicons-pause-circle:before {
    content: "\ec6b";
  }
  .linearicons-next-circle:before {
    content: "\ec6c";
  }
  .linearicons-last-circle:before {
    content: "\ec6d";
  }
  .linearicons-forward-circle:before {
    content: "\ec6e";
  }
  .linearicons-eject-circle:before {
    content: "\ec6f";
  }
  .linearicons-crop:before {
    content: "\ec70";
  }
  .linearicons-frame-expand:before {
    content: "\ec71";
  }
  .linearicons-frame-contract:before {
    content: "\ec72";
  }
  .linearicons-focus:before {
    content: "\ec73";
  }
  .linearicons-transform:before {
    content: "\ec74";
  }
  .linearicons-grid:before {
    content: "\ec75";
  }
  .linearicons-grid-crossed:before {
    content: "\ec76";
  }
  .linearicons-layers:before {
    content: "\ec77";
  }
  .linearicons-layers-crossed:before {
    content: "\ec78";
  }
  .linearicons-toggle:before {
    content: "\ec79";
  }
  .linearicons-rulers:before {
    content: "\ec7a";
  }
  .linearicons-ruler:before {
    content: "\ec7b";
  }
  .linearicons-funnel:before {
    content: "\ec7c";
  }
  .linearicons-flip-horizontal:before {
    content: "\ec7d";
  }
  .linearicons-flip-vertical:before {
    content: "\ec7e";
  }
  .linearicons-flip-horizontal2:before {
    content: "\ec7f";
  }
  .linearicons-flip-vertical2:before {
    content: "\ec80";
  }
  .linearicons-angle:before {
    content: "\ec81";
  }
  .linearicons-angle2:before {
    content: "\ec82";
  }
  .linearicons-subtract:before {
    content: "\ec83";
  }
  .linearicons-combine:before {
    content: "\ec84";
  }
  .linearicons-intersect:before {
    content: "\ec85";
  }
  .linearicons-exclude:before {
    content: "\ec86";
  }
  .linearicons-align-center-vertical:before {
    content: "\ec87";
  }
  .linearicons-align-right:before {
    content: "\ec88";
  }
  .linearicons-align-bottom:before {
    content: "\ec89";
  }
  .linearicons-align-left:before {
    content: "\ec8a";
  }
  .linearicons-align-center-horizontal:before {
    content: "\ec8b";
  }
  .linearicons-align-top:before {
    content: "\ec8c";
  }
  .linearicons-square:before {
    content: "\ec8d";
  }
  .linearicons-plus-square:before {
    content: "\ec8e";
  }
  .linearicons-minus-square:before {
    content: "\ec8f";
  }
  .linearicons-percent-square:before {
    content: "\ec90";
  }
  .linearicons-arrow-up-square:before {
    content: "\ec91";
  }
  .linearicons-arrow-down-square:before {
    content: "\ec92";
  }
  .linearicons-arrow-left-square:before {
    content: "\ec93";
  }
  .linearicons-arrow-right-square:before {
    content: "\ec94";
  }
  .linearicons-chevron-up-square:before {
    content: "\ec95";
  }
  .linearicons-chevron-down-square:before {
    content: "\ec96";
  }
  .linearicons-chevron-left-square:before {
    content: "\ec97";
  }
  .linearicons-chevron-right-square:before {
    content: "\ec98";
  }
  .linearicons-check-square:before {
    content: "\ec99";
  }
  .linearicons-cross-square:before {
    content: "\ec9a";
  }
  .linearicons-menu-square:before {
    content: "\ec9b";
  }
  .linearicons-prohibited:before {
    content: "\ec9c";
  }
  .linearicons-circle:before {
    content: "\ec9d";
  }
  .linearicons-radio-button:before {
    content: "\ec9e";
  }
  .linearicons-ligature:before {
    content: "\ec9f";
  }
  .linearicons-text-format:before {
    content: "\eca0";
  }
  .linearicons-text-format-remove:before {
    content: "\eca1";
  }
  .linearicons-text-size:before {
    content: "\eca2";
  }
  .linearicons-bold:before {
    content: "\eca3";
  }
  .linearicons-italic:before {
    content: "\eca4";
  }
  .linearicons-underline:before {
    content: "\eca5";
  }
  .linearicons-strikethrough:before {
    content: "\eca6";
  }
  .linearicons-highlight:before {
    content: "\eca7";
  }
  .linearicons-text-align-left:before {
    content: "\eca8";
  }
  .linearicons-text-align-center:before {
    content: "\eca9";
  }
  .linearicons-text-align-right:before {
    content: "\ecaa";
  }
  .linearicons-text-align-justify:before {
    content: "\ecab";
  }
  .linearicons-line-spacing:before {
    content: "\ecac";
  }
  .linearicons-indent-increase:before {
    content: "\ecad";
  }
  .linearicons-indent-decrease:before {
    content: "\ecae";
  }
  .linearicons-text-wrap:before {
    content: "\ecaf";
  }
  .linearicons-pilcrow:before {
    content: "\ecb0";
  }
  .linearicons-direction-ltr:before {
    content: "\ecb1";
  }
  .linearicons-direction-rtl:before {
    content: "\ecb2";
  }
  .linearicons-page-break:before {
    content: "\ecb3";
  }
  .linearicons-page-break2:before {
    content: "\ecb4";
  }
  .linearicons-sort-alpha-asc:before {
    content: "\ecb5";
  }
  .linearicons-sort-alpha-desc:before {
    content: "\ecb6";
  }
  .linearicons-sort-numeric-asc:before {
    content: "\ecb7";
  }
  .linearicons-sort-numeric-desc:before {
    content: "\ecb8";
  }
  .linearicons-sort-amount-asc:before {
    content: "\ecb9";
  }
  .linearicons-sort-amount-desc:before {
    content: "\ecba";
  }
  .linearicons-sort-time-asc:before {
    content: "\ecbb";
  }
  .linearicons-sort-time-desc:before {
    content: "\ecbc";
  }
  .linearicons-sigma:before {
    content: "\ecbd";
  }
  .linearicons-pencil-line:before {
    content: "\ecbe";
  }
  .linearicons-hand:before {
    content: "\ecbf";
  }
  .linearicons-pointer-up:before {
    content: "\ecc0";
  }
  .linearicons-pointer-right:before {
    content: "\ecc1";
  }
  .linearicons-pointer-down:before {
    content: "\ecc2";
  }
  .linearicons-pointer-left:before {
    content: "\ecc3";
  }
  .linearicons-finger-tap:before {
    content: "\ecc4";
  }
  .linearicons-fingers-tap:before {
    content: "\ecc5";
  }
  .linearicons-reminder:before {
    content: "\ecc6";
  }
  .linearicons-fingers-crossed:before {
    content: "\ecc7";
  }
  .linearicons-fingers-victory:before {
    content: "\ecc8";
  }
  .linearicons-gesture-zoom:before {
    content: "\ecc9";
  }
  .linearicons-gesture-pinch:before {
    content: "\ecca";
  }
  .linearicons-fingers-scroll-horizontal:before {
    content: "\eccb";
  }
  .linearicons-fingers-scroll-vertical:before {
    content: "\eccc";
  }
  .linearicons-fingers-scroll-left:before {
    content: "\eccd";
  }
  .linearicons-fingers-scroll-right:before {
    content: "\ecce";
  }
  .linearicons-hand2:before {
    content: "\eccf";
  }
  .linearicons-pointer-up2:before {
    content: "\ecd0";
  }
  .linearicons-pointer-right2:before {
    content: "\ecd1";
  }
  .linearicons-pointer-down2:before {
    content: "\ecd2";
  }
  .linearicons-pointer-left2:before {
    content: "\ecd3";
  }
  .linearicons-finger-tap2:before {
    content: "\ecd4";
  }
  .linearicons-fingers-tap2:before {
    content: "\ecd5";
  }
  .linearicons-reminder2:before {
    content: "\ecd6";
  }
  .linearicons-gesture-zoom2:before {
    content: "\ecd7";
  }
  .linearicons-gesture-pinch2:before {
    content: "\ecd8";
  }
  .linearicons-fingers-scroll-horizontal2:before {
    content: "\ecd9";
  }
  .linearicons-fingers-scroll-vertical2:before {
    content: "\ecda";
  }
  .linearicons-fingers-scroll-left2:before {
    content: "\ecdb";
  }
  .linearicons-fingers-scroll-right2:before {
    content: "\ecdc";
  }
  .linearicons-fingers-scroll-vertical3:before {
    content: "\ecdd";
  }
  .linearicons-border-style:before {
    content: "\ecde";
  }
  .linearicons-border-all:before {
    content: "\ecdf";
  }
  .linearicons-border-outer:before {
    content: "\ece0";
  }
  .linearicons-border-inner:before {
    content: "\ece1";
  }
  .linearicons-border-top:before {
    content: "\ece2";
  }
  .linearicons-border-horizontal:before {
    content: "\ece3";
  }
  .linearicons-border-bottom:before {
    content: "\ece4";
  }
  .linearicons-border-left:before {
    content: "\ece5";
  }
  .linearicons-border-vertical:before {
    content: "\ece6";
  }
  .linearicons-border-right:before {
    content: "\ece7";
  }
  .linearicons-border-none:before {
    content: "\ece8";
  }
  .linearicons-ellipsis:before {
    content: "\ece9";
  }
  